import React, { useState, Suspense } from "react"
import DaySelector from "../../../DaySelector"
import Box from "@mui/material/Box"
import moment from "moment"

import { ViewProps } from "../../ViewProps"
import { SingleDay } from "./SingleDay";
import { LoadingIndicator } from "../../../LoadingIndicator";


const SingleDayViewComp = (props: ViewProps) => {
	const today = moment().startOf("day").toDate()

	const [selectedDay, setSelectedDay] = useState(today)
	return (
		<Box>
			<DaySelector selectedDay={selectedDay}
				onDaySelected={setSelectedDay}
				today={today}
			/>
			<Suspense fallback={<LoadingIndicator />}>
				<SingleDay {...props} today={today} selectedDate={selectedDay} setSelectedDate={setSelectedDay} />
			</Suspense>
		</Box>
	)
}

export const SingleDayView = React.memo(SingleDayViewComp)